import React from 'react'

const HomeHeader = () => {
    return (
        <header className="home">
            <img src='/imgs/image4.jpeg' alt='psychic reading store front' />
            <div className='legend'>
                <h1>Kellys Psychic Reading</h1>
                <p>after 10 years of experience I have helped thousands of people come to terms with their faith, lost loved ones, and make changes to their lives. I specialize in many types of reading syles, all my readings reveal the past present and future</p>
                <ul>
                    <p>Spiritual cleansing</p>
                    <p>Spiritual healing</p>
                    <p>Chakra balancing</p>
                </ul>
            </div>
        </header>
    )
}

export default HomeHeader