import React from 'react';
import NavBar from './components/NavBar'
import HomeHeader from './components/HomeHeader'
import HomeSection from './components/HomeSection'
import HomeFooter from './components/HomeFooter'
import Contact from './components/Contact'
import './App.css';
import { Route, Switch } from 'react-router-dom'

function App() {
  return (
    <div className="App">
      <NavBar />
      <Switch>
        <Route exact path="/">
          <HomeHeader />
          <HomeSection />
        </Route>
        <Route path='/contact'>
          <Contact />
        </Route>
      </Switch>
    </div>
  );
}

export default App;
