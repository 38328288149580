import React from 'react'

const HomeSection = () => {
    return (
        <>
            <h2>Readings</h2>
            <section>
                <div className='col'>
                    <div className='container'>
                        <img className='reading' src='imgs/image0.jpeg' alt='a hand with words showing the lines that you can read to tell fortune' />
                        <div>
                            <h3>Palm Reading</h3>
                            <p>I will read the lines on your palm and it will reveal small changes in your life</p>
                        </div>
                    </div>
                    <div className='container'>
                        <img className='reading' src='imgs/image2.jpeg' alt='an assortment of tools used to tell fortune' />
                        <div>
                            <h3>Plain Card Reading</h3>
                            <p>I will layout 20 cards and I will read them in clusters and it will tell me about your life in detail</p>
                        </div>
                    </div>
                </div>
                <div className='col'>
                    <div className='container'>
                        <img className='reading' src='/imgs/image0 - Copy.jpeg' alt='tarot cards displayed on a table' />
                        <div>
                            <h3>Tarot Card Reading</h3>
                            <p>I will lay all cards out and i will be able to touch on most aspects of your life, including past, present and future</p>
                        </div>
                    </div>
                    <div className='container'>
                        <img className='reading' src='imgs/image3.jpeg' alt='a crystal ball sitting near a manaquin hand' />
                        <div>
                            <h3>Crystal Reading</h3>
                            <p>I will have you hold a crystal and make two wishes and read the energies i get</p>
                        </div>
                    </div>

                </div>
                <div className='col'>
                    <div className='container'>
                        <img className='reading' src='imgs/image1 - Copy.jpeg' alt='' />
                        <div>
                            <h3>Spiritual Reading</h3>
                            <p>I will light three candles and have you hold the tallest one. I will connect to your spirit and find your past, present and future</p>
                        </div>
                    </div>
                    <div className='container'>
                        <img className='reading' src='imgs/image1.jpeg' alt='' />
                        <div>
                            <h3>Psychic Reading</h3>
                            <p>I will hold something you touch on a daily basis and will read the energy i can feel on it</p>
                        </div>
                    </div>

                </div>
            </section>
        </>
    )
}

export default HomeSection