import React, { useState } from 'react'

const Contact = () => {
    const [contact, setContact] = useState({
        email: '',
        name: '',
        contents: ''
    })

    const handleChange = event => {
        setContact({ ...contact, [event.target.name]: event.target.value })
    }



    return (
        <>
            <form id='contact'>
                <label htmlFor='email'>Email</label>
                <input type='text' id='email' name='email' onChange={handleChange} />
                <label hmtlFor='name'>Name</label>
                <input id='name' name='name' type='text' onChange={handleChange} />
                <label htmlFor='contents'>Email Contents</label>
                <textarea id='contents' name='contents' row='15' cols='20' onChange={handleChange} />
                <button type='submit'>Submit Email</button>
            </form>
            <phone></phone>
            <address>
                <p>Phone Number: (203)-514-1577</p>
                <address>88 main street, Seymour, CT </address>
            </address>
        </>
    )
}

export default Contact